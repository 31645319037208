<template>
  <v-row justify="center">
    <v-col>
      <vue-weather class="center" api-key="ac73bde4da884ebb575fc7416d7a51b2" />
    </v-col>
  </v-row>
</template>

<script>
  import VueWeather from "vue-weather-widget";
  export default {
    name: 'Widget',
    components: {
      VueWeather,
    },
    data: () => ({}),
  }
</script>
