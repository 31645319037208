import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueGtm from 'vue-gtm'
import './assets/styles.css';

Vue.config.productionTip = false

// *** GTM
Vue.use(VueGtm, {
  id: 'GTM-TT9LQGG',
  enabled: true
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
