<template>
  <v-app>
    <Header />
    <router-view/>
    <Footer />
  </v-app>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';

export default {
  name: 'layout',
  components: {
    Header,
    Footer,
  },
  data: () => ({}),
}
</script>
<style scoped>
  .theme--light.v-application {
    background: #f2f2f2 !important;
  }
</style>
