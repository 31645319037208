<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col xl="8" md="12">
        <Widget />
        <Cards />
        <Tours />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Widget from '../components/Weather/Widget';
import Cards from '../components/Index/Cards';
import Tours from '../components/Index/Tours';
export default {
  name: 'Index',
  components: {
    Widget,
    Cards,
    Tours,
  },
  data: () => ({}),
}
</script>
<style scoped></style>
