<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col lg="3" md="4">
            <v-card>
              <v-card-title class="tours-title text-center text-capitalize" v-text="tours.title"></v-card-title>
              <v-card-text>
                <div v-for="(tour, index) in tours.list" :key="index">
                  <v-btn
                    color="cyan"
                    text
                    :href="tour.link"
                    @click="$gtm.trackEvent({
                      event: `POPULAR TOURS - ${tour.text.toUpperCase()}`
                    })"
                  >
                    <v-icon left dark small>mdi-chevron-right-circle</v-icon>
                    {{ tour.text }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col lg="9" md="8">
            <v-row>
              <v-col v-for="(card, index) in cards" :key="index" lg="4" md="6">
                <v-card>
                  <v-card-title class="justify-center">
                    <div class="text-h6 text-capitalize" v-text="card.title"></div>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-img class="center" width="220" :src="card.image"></v-img>
                    <br />
                    <p class="text-subtitle-1 font-weight-bold" v-text="card.subtitle"></p>
                    <p class="text-body-2 text-justify" v-text="card.description"></p>
                  </v-card-text>
                  <v-card-actions class="align-right">
                    <v-btn
                      color="cyan"
                      :href="card.link"
                      dark
                      block
                      @click="$gtm.trackEvent({
                        event: `TOUR COLUMN - ${card.title.toUpperCase()}`
                      })"
                    >
                      more
                      <v-icon right dark> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <br />
            <div class="text-center">
              <span v-for="(external, index) in externals" :key="index">
                <a
                  class="text-capitalize no-decoration"
                  :href="external.href"
                  @click="$gtm.trackEvent({
                    event: `EXTERNALS - ${external.text.toUpperCase()}`
                  })">
                  {{ external.text }}
                </a> | 
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Tours',
    data: () => ({
      tours: {
        title: 'popular tours',
        list: [
          {
            text: 'tulum & xel-ha',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=XELHATULUMPRIV&affid=1098'
          },
          {
            text: 'chichen itza & ik kil',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=CHICHENIKKILPRIV&affid=1098'
          },
          {
            text: 'xcaret',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=XCARETPRIV&affid=1098'
          },
          {
            text: 'jungle tour',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=JUNGLETOUR&affid=1098'
          },
          {
            text: 'selvatica extreme zip',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=EXTREMEZIP&affid=1098'
          },
          {
            text: 'chichen itza',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=CHICHENPRIV&affid=1098'
          },
          {
            text: 'capt hook',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=CAPTHOOKSTEAK&affid=1098'
          },
          {
            text: 'atv cenote & snorkel',
            link: 'http://www.entertainment-plus.net/affiliates/ATVCenote.asp?affid=1098'
          },
          {
            text: 'xel-ha',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=XELHAPRIV&affid=1098'
          },
          {
            text: 'xplor',
            link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=XPLOR&affid=1098'
          }
        ],
      },
      cards: [
        {
          image: '/images/Chichen-Itza.jpg',
          title: 'chichen itza',
          subtitle: 'Chichen Itza - One of the New 7 Wonders of the World',
          description: 'The most important archaeological zone in Yucatan, capital of the Mayan Empire and voted one of the new "Seven Wonders of the World".',
          link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=CHICHENPRIV&affid=1098'
        },
        {
          image: '/images/Xel-Ha.jpg',
          title: 'xel-ha',
          subtitle: 'Snorkel in the fish-filled protected inlet of this natural aquarium',
          description: 'Enjoy the natural beauty of Xel-ha, a natural aquarium which is ideal for snorkeling. Safe for all ages to play with the fish in this protected inlet.',
          link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=XELHAPRIV&affid=1098'
        },
        {
          image: '/images/Xcaret.jpg',
          title: 'xcaret',
          subtitle: 'Amazing animal, exotic birds, and a night show like no other!',
          description: 'Interact with nature in this Eco-Park that has everything. Snorkeling, birds, turtle farm, or you can relax in the hammocks on the beach.',
          link: 'http://www.entertainment-plus.net/affiliates/tour.asp?code=XCARETPRIV&affid=1098'
        }
      ],
      externals: [
        {
          text: 'xel-ha mexico',
          href: 'http://www.xel-ha-mexico.com/'
        },
        {
          text: 'chichen-itza mexico',
          href: 'http://www.chichen-itza-mexico.com/'
        },
        {
          text: 'cenote ik-kil',
          href: 'http://www.cenote-ik-kil.com/'
        },
        {
          text: 'xcaret mexico',
          href: 'http://www.xcaret-mexico.net/'
        },
        {
          text: 'cancun mexico coupons',
          href: 'http://www.cancunmexicocoupons.com/'
        },
        {
          text: 'swim with dolphins in cancun',
          href: 'http://www.swimwithdolphinsincancun.com/'
        },
        {
          text: 'puerto morelos mexico',
          href: 'http://www.puerto-morelos-mexico.com/'
        }
      ]
    }),
  }
</script>
<style scoped>
.v-card__subtitle, .v-card__text, .v-card__title {
  padding: 8px !important;
}
.tours-title {
  background-color: #027CC2;
  color: white;
}
</style>