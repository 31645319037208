<template>
  <v-card color="#12222a" dark>
    <v-card-text>
      <v-row no-gutters justify="center">
        <v-col class="text-center" md="12" lg="8" xl="8">
          <v-row>
            <v-col>
              <div>
                <v-btn
                  icon
                  href="https://www.facebook.com/EntertainmentPlusCancun"
                  class="social"
                  @click="$gtm.trackEvent({
                    event: 'SOCIAL - FACEBOOK'
                  })"
                >
                  <v-avatar size="38">
                    <v-icon
                      large
                      color="blue darken-2"
                    >
                      mdi-facebook
                    </v-icon>
                  </v-avatar>
                </v-btn>
              </div>
              <div
                class="unstyled-link no-decoration"
                v-text="
                  `© ${new Date().getFullYear()} Entertainment - Plus `
                "
              ></div>
            </v-col>
            <v-col class="text-right">
              <div class="unstyled-link">
                Phone: 914-0290 (local in Cancun) or 011-52-998-914-0290 (from US or Canada)
              </div>
              <div class="unstyled-link">
                E-mail: info@cancunweatherforecast.com
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'Footer',
    data: () => ({}),
  }
</script>
<style scoped>
.unstyled-link {
  color: #999 !important;
  font-weight: 500;
}
.v-sheet.v-card {
  border-radius: unset !important;
}
</style>
