<template>
  <v-card color="#027CC2" dark>
    <v-card-text>
      <v-row no-gutters justify="center">
        <v-col class="text-center" sm="12" md="12" lg="9" xl="9">
          <v-row no-gutters>
            <v-col>
              <v-img
                class="center"
                src="https://www.entertainment-plus.net/images/affiliates/logos/affi_1098.png"
                height="70"
                max-width="280">
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'Header',
    data: () => ({}),
  }
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: unset !important;
}
</style>
