<template>
  <v-container>
    <v-row justify="center">
      <v-col v-for="(item, index) in items" :key="index" xl="3" lg="3" md="4" sm="5" cols="12">
        <v-card
          outlined>
          <v-card-title
            class="text-h5 font-weight-bold text-capitalize white--text"
            :class="item.color"
            v-text="item.name">
          </v-card-title>
          <v-card-text v-text="item.description"></v-card-text>
          <v-card-actions>
            <v-btn
              class="text-center"
              :color="item.color"
              dark
              block
              :href="item.link"
              @click="$gtm.trackEvent({
                event: `CARDS - ${item.name.toUpperCase()}`
              })">
              More
              <v-icon right dark> mdi-chevron-right </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Cards',
    data: () => ({
      items: [
        {
          name: 'airport transfers',
          description: 'USA TRANSFERS - Offering Cancun and Riviera Maya airport transfers for the clients of Entertainment Plus.',
          link: 'http://www.entertainment-plus.net/affiliates/transfers/default.asp?affid=1098',
          color: 'light-blue',
        },
        {
          name: 'private tours',
          description: 'Our personalized Private Tours saves you time & avoids the crowds. Leave the driving to our courteous drivers.',
          link: 'http://www.entertainment-plus.net/affiliates/affprivate_tourslist.asp?code=PRIVATE&affid=1098',
          color: 'light-green',
        },
        {
          name: 'public tours',
          description: 'Public group tours are a great value. See Chichen Itza for as low as $55! Many more to choose from...check it out!',
          link: 'http://www.entertainment-plus.net/affiliates/affpopular_tourslist.asp?affid=1098',
          color: 'orange',
        },
        {
          name: 'sun block',
          description: 'The Batab Products are both eco-friendly and the proceeds go to benefit the Mayan Community.',
          link: 'http://www.entertainment-plus.net/affiliates/sunblock/default.asp?affid=1098',
          color: 'amber',
        }
      ]
    }),
  }
</script>
